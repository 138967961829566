import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import classNames from 'classnames'
import kebabCase from 'lodash/kebabCase'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import styles from './recipeCategories.module.css'
import { StaticQuery, graphql } from 'gatsby'

const goToCategory = event => {
  navigate(event.target.value)
}

const RecipeCategories = ({ slug }) => (
  <StaticQuery
    query={graphql`
      {
        allMarkdownRemark(
          filter: { frontmatter: { template: { eq: "recipe" } } }
        ) {
          group(field: frontmatter___categories) {
            category: fieldValue
            totalCount
          }
        }
      }
    `}
    render={data => {
      const { allMarkdownRemark } = data
      const categories = allMarkdownRemark.group

      if (!categories.length) {
        return null
      }

      return (
        <div className={styles.root}>
          <select
            className={classNames('input input--select', styles.select)}
            onChange={goToCategory}
          >
            <option value={slug}>All</option>
            {categories.map((item, index) => {
              const title =
                item.category.charAt(0).toUpperCase() + item.category.slice(1)

              return (
                <option
                  key={index}
                  value={`${slug}/category/${kebabCase(item.category)}`}
                >
                  {title}
                </option>
              )
            })}
          </select>
          <ul className={styles.list}>
            <li>
              <AniLink fade to={slug} className="button button--inverse">
                All
              </AniLink>
            </li>
            {categories.map((item, index) => (
              <li key={index}>
                <AniLink
                  fade
                  to={`${slug}/category/${kebabCase(item.category)}`}
                  className="button button--inverse"
                >
                  {item.category}
                </AniLink>
              </li>
            ))}
          </ul>
        </div>
      )
    }}
  />
)

RecipeCategories.propTypes = {
  slug: PropTypes.string,
}

RecipeCategories.defaultProps = {
  slug: '/recipes',
}

export default RecipeCategories
